import "./scss/SubmitButton.scss";

function SubmitButton({ text, size, sending, disabled, onSubmit, containerStyle, buttonStyle }) {
	let width = null, hPadding = null;
	if(size==="full") {
		hPadding = "0.65rem";
		width = "100%";
	}
	if(size==="minimal") {
		hPadding = "0.65rem";
		width = null;
	}
	return (
		<div style={{ marginLeft: 'auto', marginRight: 'auto', ...containerStyle }} className="submitButtonContainer">
			<button disabled={sending||disabled} style={{
				paddingLeft: hPadding,
				paddingRight: hPadding,
				width: width,
				margin: 0,
				...buttonStyle,
			}} onClick={onSubmit} className="btn submitButton text--bold">
				{!sending && <span style={{fontSize: '1.26em'}}>{text}</span>}
				{sending && "Saving..."}
			</button>
		</div>
	);
};
export default SubmitButton;
