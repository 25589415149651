import { useState, useEffect, Fragment } from "react";
import './scss/campaignPage.scss';
import { logout, clearAnswers, fetchScratchTicket, getPosts, uploadTemporaryFile, getQuizResults } from "./campaignActions";
import { genUUID } from "./utils/Misc.mjs";
import {showsCorrectAnswerEndPage, getCorrectAnswers, CAMPAIGN2_INFO_PAGE} from './utils/custom.mjs';

import FreeText from "./elements/FreeText"
import Image from "./elements/Image"
import Link from "./elements/Link"
import NumericPrediction from "./elements/NumericPrediction"
import RegistrationForm from "./elements/RegistrationForm"
import Spacer from "./elements/Spacer"
import SubmitButton from "./elements/SubmitButton"
import Text from "./elements/Text"
import Poll from "./elements/Poll"
import QuizResults from "./elements/QuizResults"
import ImageChoice from "./elements/ImageChoice"
import CategorizedPoll from "./elements/CategorizedPoll"
import WheelOfPrizes from "./elements/WheelOfPrizes"
import WheelOfPrizesPreview from "./elements/WheelOfPrizesPreview"
import SoccerLineUpSelector from "./elements/SoccerLineUpSelector"
import SlideShow from "./elements/SlideShow"
import Mvp from "./elements/Mvp"
import Scratch from "./elements/Scratch"
import ShareModal from './ShareModal'
import CustomCorrectAnswers from './CustomCorrectAnswers'

import { hexToRGB, removeOpacityFromRGBA } from "./utils";
import IFrame from "./elements/IFrame";
import UGC from "./elements/UGC";

function CampaignPage({ data, sending, elementsErrors, onPageSubmit, campaign, goToNextPage, result, campaignId, correctAnswer }) {
	const [campaignStyling, setCampaignStyling] = useState();
	const [userResponses, setUserResponses] = useState();
	const {id, elements} = data;
	const {
		background,
		mobileBackground,
		tint,
		font,
		primaryTextColor,
		secondaryTextColor,
		primaryColor,
		secondaryColor,
		showShare,
		multiVoting,
		restartText,
		canClear,
		clearText,
	} = campaign;

	console.log("~CampaignPage", id, userResponses)
	const setData = (id, dt) => {
		const _n = {...userResponses, [id]: dt}
		console.log('* CampaignPage sets', userResponses, '->', _n)
		console.log(_n);
		setUserResponses(_n)
	};
	function onSubmit (e, autoNext=true){
		return onPageSubmit(id, userResponses, autoNext);
	}
	const body = document.querySelector('body');

	/* Mindtrap Hack */
	const fontHackCampaigns = ['itD5SGvBqSWqO5qkIKeY', '2RasQn9RapcmBfdbVgpC', 'hPSpxyn2mQLgEeqfQqa4', 'XDaBNBvuqrD7Jw70N2Kl', 'pxYhIDJ3nAZ0ppKCZLce', 'CkYDNrwjZPJqs2DjCzNP', 'Focaphg4gEUycfwgvoXQ'];

	const calcCampaignStyling = () => {
		let d = null;
		if(background && mobileBackground){
			const b = body.getBoundingClientRect();
			d = b.width > 600 ? background : mobileBackground;
		}else{
			d = background ? background : mobileBackground;
		}
		if(!d) return;

		setCampaignStyling({
			"fontFamily": fontHackCampaigns.includes(campaignId)? 'IntelligentDesign' : font?.family,
			"fontSize": font?.size,
			"backgroundPosition": d.position ? d.position.x+" "+d.position.y : "center",
			"backgroundRepeat": d.repeat ? d.repeat : 'no-repeat',
			"backgroundSize": d.size ? d.size : 'contain',
			"backgroundImage": d.url ? "url("+d.url+")" : '',
		});
	}
	const getTintStyling = () => {
		if(!tint) return;
		return {
			"background": tint.background ? tint.background : "",
		}
	}
	let resizeObserver = null;
	try {
		resizeObserver = new ResizeObserver((entries) => {
			console.log('Observer observe...');
			calcCampaignStyling();
		});
	} catch (err) {
		console.error('Observer error:', err);
	}
	const getCampaignShareUrl = () => {
		return (window.location !== window.parent.location) ? document.referrer : 'https://app.sportovivo.com/share?campaign=' + window.location.pathname.substring(1);
	}
	const islastPage = !!data.id && campaign.pages[campaign.pages.length - 1]?.id===data.id;
	const hasRestart = () => {
		return multiVoting && islastPage;
	}
	const hasClear = () => {
		return canClear && islastPage;
	}
	const showCorrect = showsCorrectAnswerEndPage(campaignId);
	useEffect(() => {
		if(resizeObserver) resizeObserver.observe(body);
		const root = document.querySelector(':root');
		if(root){
			if(primaryColor){
				root.style.setProperty('--app-primary', primaryColor);
				root.style.setProperty('--app-primary-rgb', hexToRGB(primaryColor));
			}
			if(primaryTextColor){
				root.style.setProperty('--app-textColor', primaryTextColor);
				root.style.setProperty('--app-textColor-rgb', hexToRGB(primaryTextColor));
			}
			if(secondaryColor){
				root.style.setProperty('--app-secondary', secondaryColor);
				root.style.setProperty('--app-secondary-rgb', removeOpacityFromRGBA(secondaryColor));
			}
			if (secondaryTextColor) {
				root.style.setProperty('--app-secondaryTextColor', secondaryTextColor);
				root.style.setProperty('--app-secondaryTextColor-rgb', hexToRGB(secondaryTextColor));
			}
		}

		if(islastPage && multiVoting){
			if(!showCorrect) setTimeout(() => {
				logout();
			}, 5000)
		}
	}, [primaryColor, primaryTextColor, secondaryColor, secondaryTextColor])
	return (
		<div className="campaign" style={campaignStyling}>
			<div className="campaign__tint" style={getTintStyling()}>
				<div className="campaign__wrap">
					{elements.map(function(elementData) {
						switch(elementData.type){
							case 'freeText':
								return <FreeText key={elementData.id} data={elementData} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData}/>;
							case 'image':
								return <Image key={elementData.id} data={elementData}/>;
							case 'link':
								return <Link key={elementData.id} data={elementData}/>;
							case 'iframe':
								return <IFrame key={elementData.id} data={elementData}/>;
							case 'numericPrediction':
								return <NumericPrediction key={elementData.id} data={elementData} value={userResponses?.[elementData.id]} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData}/>;
							case 'registrationForm':
								return <RegistrationForm
									autofill={!multiVoting}
									key={elementData.id}
									data={elementData}
									value={userResponses?.[elementData.id]}
									sending={sending}
									elementErrors={elementsErrors[elementData.id]}
									button={campaign.buttons}
									onChange={setData}
									onSubmit={onSubmit}
								/>;
							case 'spacer':
								return <Spacer key={elementData.id} data={elementData}/>;
							case 'submitButton':
								return <Fragment key={elementData.id}>
									{ id===CAMPAIGN2_INFO_PAGE && <CustomCorrectAnswers pages={campaign.pages} correctAnswers={getCorrectAnswers(campaignId)}/> }
									<SubmitButton
										key={elementData.id}
										text={elementData.text}
										size={elementData.width==="auto"?campaign.buttons?.submitSize:elementData.width}
										sending={sending}
										onSubmit={onSubmit}
										containerStyle={{marginTop:elementData.marginTop, textAlign:elementData.textAlign}}
										buttonStyle={{background:elementData.background, color:elementData.textColor, fontSize:elementData.fontSize, borderRadius:campaign.buttons?.borderRadius}}
									/>
								</Fragment>;
							case 'text':
								return <Text key={elementData.id} data={elementData}/>;
							case 'poll':
							case 'multipleChoice':
								return <Poll key={elementData.id} data={elementData} value={userResponses?.[elementData.id]} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData} onSubmit={onSubmit} correctAnswer={correctAnswer}/>;
							case 'quizResults':
								return <QuizResults key={elementData.id} data={elementData} getQuizResults={()=>getQuizResults(campaignId, id)}/>;
							case 'imageChoice':
								return <ImageChoice key={elementData.id} data={elementData} value={userResponses?.[elementData.id]} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData} onSubmit={onSubmit}/>;
							case 'categorizedPoll':
								return <CategorizedPoll key={elementData.id} data={elementData} value={userResponses?.[elementData.id]} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData} onSubmit={onSubmit}/>;
							case 'wheelOfPrizes':
								return <WheelOfPrizes
									key={elementData.id}
									data={elementData}
									value={userResponses?.[elementData.id]}
									sending={sending}
									elementErrors={elementsErrors[elementData.id]}
									onChange={setData}
									onSubmit={onSubmit}
									goToNextPage={goToNextPage}
									result={result}
									primaryTextColor={primaryTextColor}
									button={campaign.buttons}
								/>;
							case 'wheelOfPrizesPreview':
								return <WheelOfPrizesPreview
									key={elementData.id}
									data={elementData}
									campaign={campaign}
									value={userResponses?.[elementData.id]}
									sending={sending}
									elementErrors={elementsErrors[elementData.id]}
									onChange={setData}
									onSubmit={onSubmit}
									goToNextPage={goToNextPage}
									result={result?.responses?.[id]||null}
									primaryTextColor={primaryTextColor}
									button={campaign.buttons}
								/>;
							case 'soccerLineupSelector':
								return <SoccerLineUpSelector key={elementData.id} data={elementData} value={userResponses?.[elementData.id]} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData} onSubmit={onSubmit}/>;
							case 'slideShow':
								return <SlideShow key={elementData.id} data={elementData}/>
							case 'mvp':
								return <Mvp key={elementData.id} data={elementData} value={userResponses?.[elementData.id]} sending={sending} elementErrors={elementsErrors[elementData.id]} onChange={setData} onSubmit={onSubmit} />;
							case 'scratch':
								return <Scratch
									key={elementData.id}
									data={elementData}
									value={userResponses?.[elementData.id]}
									sending={sending}
									elementErrors={elementsErrors[elementData.id]}
									onChange={setData}
									onSubmit={onSubmit}
									goToNextPage={goToNextPage}
									fetchTicket={fetchScratchTicket}
									result={result}
									primaryTextColor={primaryTextColor}
									campaignId={campaignId}
									button={campaign.buttons}
								/>;
							case 'ugc':
								return <UGC
									key={elementData.id}
									data={elementData}
									value={userResponses?.[elementData.id]}
									onChange={setData}
									onSubmit={onSubmit}
									fetchPosts={getPosts}
									uploadTemporaryFile={uploadTemporaryFile}
									genUUID={genUUID}
									campaignId={campaignId}
									button={campaign.buttons}
								/>
							default:
								console.error('Invalid type', elementData.type);
								return <></>;
						}
					})}
					{ showShare && <ShareModal isStatic={true} campaign_url={getCampaignShareUrl()} />}
					{ hasRestart() &&
						<div className="campaign__revote">
							<div className="btn btn-secondary btn-rounded" style={{ width: '150px', margin: 'auto' }} onClick={logout}>
								<span className="material-icons">refresh</span>
								{(restartText===null||restartText===undefined)?"Restart":restartText}
							</div>
						</div>
					}
					{ hasClear() &&
						<div className="campaign__revote">
							<div className="btn btn-secondary btn-rounded" style={{ width: '150px', margin: 'auto' }} onClick={()=>clearAnswers(campaignId).then(()=>window.location.reload())}>
								<span className="material-icons">refresh</span>
								{(clearText===null||clearText===undefined)?"Clear":clearText}
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CampaignPage;
