import { useState, useEffect } from "react";
import "./scss/FormFieldCheckbox.scss";
function FormFieldCheckbox({data, value, sending, elementErrors, onChange}) {
	const [err, setErr] = useState(false);
	if(value===undefined) value=false;
	const required = data.required;

	console.log('~FormFieldCheckbox', data)
	function setData(e){
		setErr(false);
		let targetValue = e.target.checked;
		console.log('* FormFieldCheckbox sends:', data.id, targetValue)
		onChange(data.id, targetValue);
	}
	const getInputStyling = () => {
		let className = "FormFieldCheckbox md__input mtop--15";
		if(err)	className += ' input--error';
		return className;
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function(e){
			setErr(true);
		});
	}, [elementErrors])

	return (
		<div className={getInputStyling()}>
			<input onChange={setData} checked={value} disabled={sending} placeholder={data.placeholder} type="checkbox"/>
			&nbsp;
			{data.label && <span dangerouslySetInnerHTML={{__html: data.label}} className="label-content"></span>}
			{ required ? <b className="text--danger">*</b> : '' }
			<div dangerouslySetInnerHTML={{__html: data?.html}}></div>
			{elementErrors?.errors.map((error)=> <div className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default FormFieldCheckbox;
