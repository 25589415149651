import { useEffect, useState, useRef, useLayoutEffect } from "react";
import "./scss/Scratch.scss";
import ScratchCard from 'react-scratchcard-v5';
import defaultCoverImage from './scratchPattern.png';
import confetti from '../animations/confetti.json'
import confettiSecondary from '../animations/confettiSecondary.json'
import scratchLoader from '../animations/scratch-loader.json'
import scratchHand from '../animations/scratch-hand.json'
import lostAnimationOG from '../animations/neutral.json'
import Lottie from 'react-lottie-player'
import SubmitButton from "./SubmitButton";

const disableOverscroll = () => {
	document.getElementsByTagName('html')[0].className = "stopOverscroll";
	document.getElementsByTagName('body')[0].className = "stopOverscroll";
}

const enableOverscroll = () => {
	document.getElementsByTagName('html')[0].className = "";
	document.getElementsByTagName('body')[0].className = "";
}

const preventDefault = (e) => {
	e.preventDefault()
}

const disablePointerEvents = (elem) => {
	elem.addEventListener('touchstart', preventDefault, {passive: false})
	elem.addEventListener('touchend', preventDefault, {passive: false})
}

const enablePointerEvents = (elem) => {
	elem.removeEventListener('touchstart', preventDefault)
	elem.removeEventListener('touchend', preventDefault)
}

const SCRATCH_STATES = {
	Init: 0,
	TicketRequested: 1,
	TicketFetched: 2,
	ScratchStarted: 3,
	ScratchFinished: 4,
	ScratchError: 5,
}

const hexToRGB = function (color) {
	if(typeof color !== 'string') return [0,0,0];
	color = color.replace('#', '');
	const MAX = color.length===3?15:255;
	const len = color.length===3?1:2;
	const r = parseInt(color.substring(0, len), 16);
	const g = parseInt(color.substring(len, len*2), 16);
	const b = parseInt(color.substring(len*2, len*3), 16);
	return [r/MAX, g/MAX, b/MAX];
}

const replaceColor = function(color, lostAnimationOG) {
	const c = hexToRGB(color);
	lostAnimationOG.assets.forEach(function(asset){
		asset.layers.forEach(function(layer){
			layer.shapes.forEach(function(shape){
				shape.it.forEach(function(it){
					if(it.mn?.toLowerCase().includes('fill')){
						it.c.k.forEach(function(k){
							k.s = c;
						});
					}
				});
			});
		});
	});
	return lostAnimationOG;
}

function Scratch({ campaignId, data, value, sending, elementErrors, onChange, onSubmit, goToNextPage, result, primaryTextColor, isDemo, button, fetchTicket: fetchScratchTicket }) {
	const [scratchPrize, setScratchPrize] = useState(null);
	const [scratchState, setScratchState] = useState(SCRATCH_STATES.Init)
	const [isTimeoutError, setIsTimeoutError] = useState(false)
	const [isFatalError, setIsFatalError] = useState(false)
	const [isWin, setIsWin] = useState(false)
	const [scratchHeight, setScratchHeight] = useState(0)
	const scratchContent = useRef();
	const overscrollTimeout = useRef();
	const pingTicketInterval = useRef();
	const intervalStartTime = useRef();

	const calculatedWidth = scratchHeight * 9/14 - 40;
	const calculatedHeight = (scratchHeight * 9/14 - 40)*14/9;

	useLayoutEffect(() => {

		const scratchContentElem = scratchContent.current

		disableOverscroll()
		disablePointerEvents(scratchContentElem)

		return () => {
			enableOverscroll()
			if(overscrollTimeout.current){
				clearTimeout(overscrollTimeout.current)
			}
			enablePointerEvents(scratchContentElem)

			if(pingTicketInterval.current){
				clearTimeout(pingTicketInterval.current)
			}
		}
	}, [])

	useEffect(() => {
		if(scratchContent?.current?.offsetHeight < 200){
			setScratchHeight(300)
		}
		else{
			setScratchHeight(scratchContent?.current?.offsetHeight)
		}
		if(!isDemo){
			activateScratch()
		}
	}, [])

	const enableOverscrollDelayed = (ms) => {
		if(overscrollTimeout.current){
			clearTimeout(overscrollTimeout.current)
		}
		overscrollTimeout.current = setTimeout(enableOverscroll, ms)
	}

	const {
		coverImage,
		nextButtonText,
		scratchText,
		loaderMessage,
		winMessage,
		lostMessage,
		backgroundColor,
		textColor,
		buttonBackgroundColor,
		buttonTextColor,
		finishPercent,
		brushSize
	} = data;

	const stateBefore = (state) => {
		return scratchState < state
	}

	const stateExact = (state) => {
		return scratchState === state
	}

	const stateExactAfter = (state) => {
		return scratchState >= state
	}

	const TICKET_PING_PERIOD = 5000*60; //5 minutes 5000*60
	const TICKET_PING_TIMES = 5;
	const startTicketInterval = () => {
		if(pingTicketInterval.current){
			clearInterval(pingTicketInterval.current)
		}
		intervalStartTime.current = new Date().getTime()
		pingTicketInterval.current = setInterval( () => {
			const now = new Date().getTime()
			console.log("now - intervalStartTime", now - intervalStartTime.current)
			if( now - intervalStartTime.current > TICKET_PING_PERIOD*TICKET_PING_TIMES){
				clearInterval(pingTicketInterval.current)
				//session timeout]
				setScratchState(SCRATCH_STATES.ScratchError)
				setIsTimeoutError(true)
				console.log("session timeout")
				return
			}
			fetchScratchTicket(campaignId, data.id).then(res=>{
				//does not changes scratchPrize, just gets current value
				setScratchPrize( prize => {
					let isError = false
					if(prize && res.prize){
						if(prize?.id !== res.prize?.id)
							isError = true
					}
					else if(prize || res.prize){ //if one is null and the other is not
						isError = true
					}

					if(isError){
						setScratchState(SCRATCH_STATES.ScratchError)
						setIsFatalError(true)
						console.log("prize id conflict - fatal error")
					}

					return prize; //returns the same
				})

			})
		}, TICKET_PING_PERIOD)
	}
	const activateScratch = (e) => {
		if(stateBefore(SCRATCH_STATES.TicketRequested)){
			setScratchState(SCRATCH_STATES.TicketRequested)
			fetchScratchTicket(campaignId, data.id)
			.then(res=>{
				setScratchPrize(res.prize);
				if(res.prize){
					setIsWin(true)
				}
				else{
					setIsWin(false)
				}
				setScratchState(SCRATCH_STATES.TicketFetched)
				onChange(data.id, res.prize?res.prize.id:null);
			})

			startTicketInterval()
		}
		return false;
	}

	const onScratchStarted = () => {
		if(scratchState < SCRATCH_STATES.ScratchStarted){
			setScratchState(SCRATCH_STATES.ScratchStarted)
		}
	}

	return (
		<div className="scratch" style={{background: backgroundColor, color: textColor}}>

			<div className="scratch__header">
				{ stateBefore(SCRATCH_STATES.ScratchFinished) && scratchText && <div className="scratch__coverText" dangerouslySetInnerHTML={{ __html: scratchText }}></div> }
				{ isWin && stateExact(SCRATCH_STATES.ScratchFinished) &&
					<div className="scratch-won">
						<div className="text--center" dangerouslySetInnerHTML={{ __html: winMessage || 'Congratulations you won' }}></div>
						<div className="scratch-wonConfetti">
							<div className="scratch-wonConfettiA">
								{(campaignId!=='92KRZyh5E3mNiM6yXCcd') && <>
									<Lottie
										loop
										animationData={confettiSecondary}
										play
										style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}
									/>
									<Lottie
										loop
										animationData={confetti}
										play
										style={{ width: '100%', height: '100%', top: 0, left: 0 }}
									/>
								</>}
							</div>
						</div>
					</div>
				}
			</div>

			<div className="scratch__content" style={{color: textColor}}>
				<div className="scratch__wrapper" ref={scratchContent} onMouseDown={onScratchStarted} onTouchStart={onScratchStarted}>
					{/* <div> height: {(() => { console.log("offsetHeight: ", scratchContent?.current?.offsetHeight); return scratchContent?.current?.offsetHeight})()}, width: {scratchContent?.current?.offsetHeight*9/14}</div> */}
					{stateExact(SCRATCH_STATES.TicketRequested) && !isDemo &&
						<div className="scratch__loader">
							<Lottie
								loop
								animationData={scratchLoader}
								play
								style={{width: '280px'}}
							/>
							<div className="text--center" style={{position: 'relative', top: -80 }}>
								<div dangerouslySetInnerHTML={{ __html: loaderMessage || "<h1>Get ready to scratch...</h1>" }}></div>
							</div>
						</div>
					}
					{stateExact(SCRATCH_STATES.TicketFetched) &&
						<div className="scratch__hand">
							<Lottie
								loop
								animationData={scratchHand}
								play
							/>
						</div>
					}

					{(stateExactAfter(SCRATCH_STATES.TicketFetched) || isDemo) && scratchHeight && scratchContent &&
						<ScratchCard
							width={calculatedWidth}
							height={calculatedHeight}
							image={coverImage || defaultCoverImage}
							quality={1.5}
							finishPercent={finishPercent || 40}
							brushSize={brushSize || 30}
							onComplete={ async () => {
								const isResultSubmitted = await onSubmit(null, false) //make the scratch result official
								if(isResultSubmitted){
									setScratchState(SCRATCH_STATES.ScratchFinished)
								}
								else{
									setScratchState(SCRATCH_STATES.ScratchError)
									setIsFatalError(true)
								}
								enablePointerEvents(scratchContent.current)
								enableOverscrollDelayed(3000)

								if(overscrollTimeout.current){
									clearTimeout(overscrollTimeout.current)
								}
								if(pingTicketInterval.current){
									clearTimeout(pingTicketInterval.current)
								}
							}}
						>
							<div style={{
								display: 'flex',
								width: calculatedWidth,
								height: calculatedHeight,
								top: 0,
								left: 0,
								position: 'absolute',
								alignItems: 'center',
								justifyContent: 'center',
								overflow: 'hidden',
							}}>
								{ isWin && stateExactAfter(SCRATCH_STATES.TicketFetched) &&
									<div className="text--center" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
										<div style={{overflow: 'hidden'}}>
											<img src={scratchPrize?.image}  alt="Prize Image" style={{maxWidth: "99%", maxHeight: "99%"}} />
										</div>
										<div className="pbottom--5">
											<h2 style={{fontSize: '2em'}}>{scratchPrize?.text}</h2>
											<div>{scratchPrize?.description}</div>
										</div>
									</div>
								}
								{!isWin && stateExactAfter(SCRATCH_STATES.TicketFetched) &&
									<div className="text--center">
										<div dangerouslySetInnerHTML={{ __html: lostMessage || 'Better luck next time!' }}></div>
										<Lottie
											loop
											animationData={(textColor||primaryTextColor)?replaceColor((textColor||primaryTextColor), lostAnimationOG):lostAnimationOG}
											play
											style={{ maxWidth: 300, maxHeight: 300, display: 'inline-block' }}
										/>
									</div>
								}
							</div>
						</ScratchCard>
					}
				</div>
			</div>
			<div className="scratch__footer">
				{ stateExact(SCRATCH_STATES.ScratchFinished) &&
					<div className="text--center" style={{ position: 'fixed', bottom: '64px', left: 0, right: 0 }} >
						<SubmitButton
							text={nextButtonText || "Next"}
							size={button?.submitSize}
							onSubmit={goToNextPage}
							buttonStyle={{borderRadius:button?.borderRadius, background: buttonBackgroundColor, color: buttonTextColor}}
						/>
					</div>
				}
			</div>

			{ isTimeoutError &&
				<div className="modal-overlay active">
					<div className="modal active animate__animated animate__fadeIn">
						<div className="modal__header">
							<div className="modal__header-title">
								<h2 className="ptop--20 pright--20 pbottom-20 pleft--20">Timeout Error</h2>
							</div>

						</div>
						<div className="modal-content">
							<div className="modal__body">
								<p className="text--center">
									Your session has timeout out. You can try again by pressing refresh.
								</p>
								<div className="btn btn-secondary btn-rounded" style={{ width: '150px', margin: 'auto' }} onClick={()=>window.location.reload()}>
									<span className="material-icons">
										refresh
									</span>
									Refresh
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			{ isFatalError &&
				<div className="modal-overlay active">
					<div className="modal active animate__animated animate__fadeIn">
						<div className="modal__header">
							<div className="modal__header-title">
								<h2 className="ptop--20 pright--20 pbottom-20 pleft--20">Fatal Error</h2>
							</div>
						</div>
						<div className="modal-content">
							<div className="modal__body">
								<p className="text--center">
									A fatal error has occured. You can try again by pressing refresh.
								</p>
								<div className="btn btn-secondary btn-rounded" style={{ width: '150px', margin: 'auto' }} onClick={()=>window.location.reload()}>
									<span className="material-icons">
										refresh
									</span>
									Refresh
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
};
export default Scratch;
